import { PLATFORM } from 'aurelia-pal';

export function configure(config) {
    config.globalResources(
        [
            PLATFORM.moduleName('./components/form-actions'),
            PLATFORM.moduleName('./components/form-boolean-options-select2'),
            PLATFORM.moduleName('./components/form-boolean-statuses-select2'),
            PLATFORM.moduleName('./components/form-bootstrap-multiselect'),
            PLATFORM.moduleName('./components/form-counties-select2'),
            PLATFORM.moduleName('./components/form-checkbox'),
            PLATFORM.moduleName('./components/form-districts-select2'),
            PLATFORM.moduleName('./components/form-duallistbox'),
            PLATFORM.moduleName('./components/form-fancytree'),
            PLATFORM.moduleName('./components/form-file-dropzone'),
            PLATFORM.moduleName('./components/form-input'),
            PLATFORM.moduleName('./components/form-material-ui-date-picker'),
            PLATFORM.moduleName('./components/form-material-ui-datetime-picker'),
            PLATFORM.moduleName('./components/form-material-ui-time-picker'),
            PLATFORM.moduleName('./components/form-multiselect-native'),
            PLATFORM.moduleName('./components/form-pickadate'),
            PLATFORM.moduleName('./components/form-quill'),
            PLATFORM.moduleName('./components/form-select2'),
            PLATFORM.moduleName('./components/form-summernote'),
            PLATFORM.moduleName('./components/form-textarea'),
            PLATFORM.moduleName('./components/form-uniform-file'),
            PLATFORM.moduleName('./components/form-user-multiselect'),
            PLATFORM.moduleName('./components/form-user-entity-team-lot-multiselect'),
            PLATFORM.moduleName('./components/form-user-select2'),
            PLATFORM.moduleName('./components/form-users-bootstrap-multiselect'),
            PLATFORM.moduleName('./components/form-users-duallistbox'),
            PLATFORM.moduleName('./components/form-field-group'),
            PLATFORM.moduleName('./components/form-divider'),
            PLATFORM.moduleName('./form-alert'),
            PLATFORM.moduleName('./form-attributes'),
            PLATFORM.moduleName('./form-field'),
            PLATFORM.moduleName('./form-fields'),
            PLATFORM.moduleName('./form-group'),
            PLATFORM.moduleName('./schema-form'),
            PLATFORM.moduleName('./components/form-acbin-duallistbox'),
            PLATFORM.moduleName('./components/form-select-box'),
        ],
    );
}
